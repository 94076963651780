import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

const Index = React.lazy(() => import('./pages/Index'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Terms = React.lazy(() => import ('./pages/Terms'));
const NotFoundPage = React.lazy(() => import('./pages/NotFound'));
const ReturnPolicy = React.lazy(() => import ('./pages/Return'));

function App() {
    return (
        <>
            <Suspense fallback={
                <div className={'h-screen w-screen flex justify-center items-center bg-bgLight'}>
                    <p className={'text-center text-primary text-2xl lg:text-9xl font-semibold'}>Loading...!</p>
                </div>
            }>
                <BrowserRouter>
                    <Routes>
                        <Route exact path='/' element={<Index/>}/>
                        <Route exact path='/privacy' element={<Privacy/>}/>
                        <Route exact path='/termsofuse' element={<Terms/>}/>
                        <Route exact path='/return-policy' element={<ReturnPolicy/>}/>
                        <Route path='/meal/*' element={<AppRedirect/>}/>
                        <Route path='/restaurant/*' element={<AppRedirect/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </>
    );
}

const AppRedirect = () => {
    React.useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        let redirectUrl;

        if (/android/i.test(userAgent)) {
            redirectUrl = `https://play.google.com/store/apps/details?id=com.cuberobotics.gh_delivery`;
        } else if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
            redirectUrl = `https://apps.apple.com/app/id1613176191`;
        } else {
            redirectUrl = 'https://flip.delivery/';
        }

        window.location.href = redirectUrl;
    }, []);

    return <div>Redirecting to app store...</div>;
};
export default App;
